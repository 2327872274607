/* eslint-disable  jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import toReact from 'svelte-adapter/react';
import { Button } from '@farmers-fridge/ui-toolbox';
import { Prompt } from 'react-router';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';
import ShortUniqueId from 'short-unique-id';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from '../../atoms/forms/Select';
import styledComponent from '../../utils/styledComponent';
import {
  CreateOrderMutation,
  Items,
  GetOpsLocations,
  ReadAllPackingSlipGroupValues,
  OrderMeta,
  ViewPars,
} from '../../libs/GraphQL';
import CreateOptions from './components/CreateOptions';
import track from '../../utils/analytics';
import Summary from '../../atoms/icons/Summary';
import Detail from '../../atoms/icons/Detail';
import { uploadFileToS3 } from '../../libs/uploadToS3';
import Loading from '../../atoms/Loading';

import 'react-toastify/dist/ReactToastify.css';

const toastOptions = {
  className: 'prettyToast',
  position: 'top-center',
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  theme: 'light',
};

const FFButtonComp = toReact(Button, null, 'div');

const StyledSection = styledComponent('section', {
  '& .actions': {
    float: 'right',
    marginBottom: '100px',
    marginTop: '0px',
  },
  '& #file': {
    border: 'none',
  },
  '& form': {
    marginTop: '16px',
  },
  '& .dropdowns': {
    '& .selectWrapper': {
      marginRight: '32px',
    },
    display: 'flex',
    justifyContent: 'left',
  },
  '& .errors': {
    '.colorBar': {
      '& span': {
        lineHeight: '0.8',
      },
      alignContent: 'center',
      background: '#D0021A',
      color: '#FFF',
      display: 'flex',
      fontSize: '22px',
      height: '50px',
      padding: '16px',
      width: '100%',
    },
    '& .errorList': {
      '& li': {
        marginBottom: '16px',
      },
      margin: '8px',
    },
    '& .title': {
      margin: '8px',
    },
    border: '1px solid #D0021A',
  },
  '& .filePreview': {
    '& td': {
      padding: '10px',
    },
    '& tr:nth-child(even)': {
      background: '#f7f6f6',
    },
    '& thead tr': {
      background: '#c4c4c4',
    },
    '& thead th': {
      borderColor: '#4f4f4f',
    },
    marginBottom: '16px',
    width: '100%',
  },
  '& .selectWrapper label,  .fileWrapper label': {
    fontSize: '14px',
    fontWeight: 'normal',
  },
  '& .submitError': {
    color: '#D0021A',
  },
  '& .successMessage': {
    '& h1': {
      margin: '32px 0',
    },
  },
  '& .uploadSummaryItems': {
    alignContent: 'center',
    background: '#EEEEEE',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '16px 0',
    padding: '8px',
  },
  '& .uploadSummary': {
    '& li': {
      listStyle: 'none',
      padding: 0,
    },
    margin: '0 0 16px 0',
    padding: 0,
  },
  '& .viewNavigation': {
    '& a': {
      '& svg': {
        fill: '#000',
        marginRight: '6px',
      },
      alignContent: 'center',
      border: '1px solid #000',
      color: '#000',
      display: 'flex',
      marginTop: '16px',
      padding: '8px 16px',
    },
    '& .active': {
      '& svg': {
        fill: '#fff',
      },
      background: '#000',
      color: '#fff',
    },
    '& li': {
      listStyle: 'none',
    },
    '& ul': {
      display: 'flex',
      padding: 0,
      margin: '0 0 16px 0',
    },
  },
  '& .loadingWrapper': {
    marginTop: '16px',
    width: '100%',
    '& p': {
      fontSize: '16px',
      margin: '0',
      '& b': {
        fontSize: '20px',
      },
    },
    '& .loading': {
      width: '100%',
      height: '26px',
      background: 'rgba(0, 0, 0, 0.18);',
      marginBottom: '8px',
      position: 'relative',
      textAlign: 'center',
      fontWeight: 'bold',
      lineHeight: '24px',
      borderRadius: '12px',
      overflow: 'hidden',

      '& .bar': {
        width: '50%',
        height: '100%',
        background: '#368007',
        position: 'absolute',
        borderRadius: '12px',
        top: 0,
        left: 0,
        transition: 'width 0.4s ease-in-out',
      },

      '& .barText': {
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
        color: '#ffffff',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.20)',
      },
    },
  },
});

// Check to see if region is valid
const regions = {
  DC: 'DC',
  ALABAMA: 'AL',
  ALASKA: 'AK',
  ARIZONA: 'AZ',
  ARKANSAS: 'AR',
  CALIFORNIA: 'CA',
  COLORADO: 'CO',
  CONNECTICUT: 'CT',
  DELAWARE: 'DE',
  FLORIDA: 'FL',
  GEORGIA: 'GA',
  HAWAII: 'HI',
  IDAHO: 'ID',
  ILLINOIS: 'IL',
  INDIANA: 'IN',
  IOWA: 'IA',
  KANSAS: 'KS',
  KENTUCKY: 'KY',
  LOUISIANA: 'LA',
  MAINE: 'ME',
  MARYLAND: 'MD',
  MASSACHUSETTS: 'MA',
  MICHIGAN: 'MI',
  MINNESOTA: 'MN',
  MISSISSIPPI: 'MS',
  MISSOURI: 'MO',
  MONTANA: 'MT',
  NEBRASKA: 'NE',
  NEVADA: 'NV',
  NEW_HAMPSHIRE: 'NH',
  NEW_JERSEY: 'NJ',
  NEW_MEXICO: 'NM',
  NEW_YORK: 'NY',
  NORTH_CAROLINA: 'NC',
  NORTH_DAKOTA: 'ND',
  OHIO: 'OH',
  OKLAHOMA: 'OK',
  OREGON: 'OR',
  PENNSYLVANIA: 'PA',
  RHODE_ISLAND: 'RI',
  SOUTH_CAROLINA: 'SC',
  SOUTH_DAKOTA: 'SD',
  TENNESSEE: 'TN',
  TEXAS: 'TX',
  UTAH: 'UT',
  VERMONT: 'VT',
  VIRGINIA: 'VA',
  WASHINGTON: 'WA',
  WEST_VIRGINIA: 'WV',
  WISCONSIN: 'WI',
  WYOMING: 'WY',
};

const OrdersBulkCreate = ({ history }) => {
  const [submitError, setSubmitError] = useState(false);
  const [fileContents, setFileContents] = useState(null);
  const [fileContentsRaw, setFileContentsRaw] = useState(null);
  const [formattedFileContents, setFormattedFileContents] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [items, setItems] = useState([]);
  const [locations, setLocations] = useState([]);
  const [fileErrors, setFileErrors] = useState(null);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true);
  const [shouldShowSuccess, setShouldShowSuccess] = useState(false);
  const [orderType, setOrderType] = useState('');
  const [clientCode, setClientCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderInfoDisplayType, setOrderInfoDisplayType] = useState('summary');
  const [opportunityNames, setOpportunityNames] = useState({});
  const [orderUploadId, setOrderUploadId] = useState(null);
  const [salesforceFields, setSalesforceFields] = useState({});
  const [isInitLoading, setIsInitLoading] = useState(true);
  const [curOrders, setCurOrders] = useState([]);
  const [curPars, setCurPars] = useState([]);
  const [invalidOrderItems, setInvalidOrderItems] = useState([]);
  const [invalidParItems, setInvalidParItems] = useState([]);
  const [numFiles, setNumFiles] = useState(0);
  const [completedFiles, setCompletedFiles] = useState(0);
  const [isValidationLoading, setIsValidationLoading] = useState(false);
  let invalidSet = new Set();
  const progressPercent =
    numFiles > 0 ? Math.round((completedFiles / numFiles) * 100) : 0;
  const ordersPercent = Math.round((Object.keys(curOrders)?.length / 15) * 100);
  const initialProgressPercent =
    !locations?.length > 0 ? Math.round(ordersPercent * 0.95) : ordersPercent;
  const [failedOrdersInfo, setFailedOrdersInfo] = useState([]);

  useEffect(() => {
    setIsInitLoading(true);
    const fetchData = async () => {
      try {
        const today = moment().format('YYYY-MM-DD');
        await fetchDataForNextDays(ViewPars, setCurPars, today);
        await fetchDataForNextDays(
          OrderMeta.bind(null, null, null),
          setCurOrders,
          today,
        );
        if (items.length === 0) {
          await getLiveVendItems();
        }
        if (locations.length === 0) {
          await getLocationIds();
        }
        if (items.length > 0 && locations.length > 0) {
          setIsInitLoading(false);
        }
      } catch (error) {
        console.error(error);
        setSubmitError(true);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (invalidOrderItems.length > 0 || invalidParItems.length > 0) {
      setIsValidationLoading(false);
    }
  }, [invalidOrderItems, invalidParItems]);
  const fetchDataForNextDays = async (fetchDataFunc, setStateFunc, today) => {
    try {
      const promises = [];
      for (let i = 0; i <= 14; i++) {
        const date = moment(today).add(i, 'days').format('YYYY-MM-DD');
        const promise = fetchDataFunc(date).then((dataObj) => {
          const data =
            dataObj.data?.jitLocations || dataObj.data?.ordersByDay.orders;
          if (data) {
            setStateFunc((prev) => ({
              ...prev,
              [date]: data,
            }));
            return data;
          } else {
            return {};
          }
        });
        promises.push(promise);
      }
      const allData = await Promise.all(promises);
      return allData;
    } catch (e) {
      console.log(e);
      setSubmitError(true);
      setIsInitLoading(false);
    }
  };

  const getLiveVendItems = async () => {
    try {
      const liveItems = await Items();
      const tempItems = [];
      for (const idx in liveItems.data.items) {
        tempItems.push(liveItems.data.items[idx].id);
      }
      setItems(tempItems);
    } catch (e) {
      console.error(e);
      setSubmitError(true);
    }
  };

  const getLocationIds = async () => {
    try {
      const locations = await GetOpsLocations();
      const locationIds = {};
      const opportunityNames = {};
      const tempSalesforceFields = {};
      for (const idx in locations.data.opsLocations.locations) {
        const location = locations.data.opsLocations.locations[idx];
        locationIds[location.id] = location.type;
        opportunityNames[location.id] = location.opportunityName;
        tempSalesforceFields[location.id] = {
          fulfillmentCenter: location.fulfillmentCenter,
          routingTag: location.routingTag,
        };
        if (location.type == 'FRIDGE') {
          tempSalesforceFields[location.id] = {
            ...tempSalesforceFields[location.id],
            parkToFridgeInstructions: location.parkToFridgeInstructions,
          };
        } else if (location.type == 'WHOLESALE') {
          tempSalesforceFields[location.id] = {
            ...tempSalesforceFields[location.id],
            deliveryInstructions: location.deliveryInstructions,
            timeZone: location.timeZone,
            status: location.status,
          };
        }
      }
      locationIds.KITCHEN = 'INTERNAL';
      setSalesforceFields(tempSalesforceFields);
      setOpportunityNames(opportunityNames);
      setLocations(locationIds);
    } catch (e) {
      console.error(e);
      setSubmitError(true);
    }
    setIsInitLoading(false);
  };

  const createOrders = async ({ clientCode, orderType }) => {
    const localFileContents = fileContents.data;
    const remainingItems = [];
    const groupedOrders = {};
    const allPackingSlipGroupValues = await ReadAllPackingSlipGroupValues();
    const allPackingSlipGroupValuesDic = {};
    const getUploadId = new ShortUniqueId({ length: 8 });
    // using temporary variable uploadId since setState is async and orderUploadId will not immediately reflect the value
    const uploadId = getUploadId();
    setOrderUploadId(uploadId);
    for (
      let i = 0;
      i < allPackingSlipGroupValues.data.getAllPackingSlipGroups.length;
      i++
    ) {
      const stateCode =
        allPackingSlipGroupValues.data.getAllPackingSlipGroups[i].stateCode;
      const packingSlipGroupValue =
        allPackingSlipGroupValues.data.getAllPackingSlipGroups[i]
          .packingSlipGroup;
      allPackingSlipGroupValuesDic[stateCode] = packingSlipGroupValue;
    }
    for (let i = 0; i < localFileContents.length; i++) {
      const obj = localFileContents[i];
      if (validateline(obj).status === 1) {
        const currentSession = await Auth.currentAuthenticatedUser();
        const locId = obj.locationId.split('#')[0];
        const delDate = obj.deliveryDate;
        const dropDate = obj.dropOffDate;
        const groupKey = `${delDate}#${dropDate}`;
        const completeObject = {
          locationId: locId,
          orderOwner: currentSession.signInUserSession.idToken.payload.email,
          orderType,
          addressForDelivery: obj.locationId === 'KITCHEN' ? 'KITCHEN' : locId,
          deliveryDate: delDate.substring(0, 10),
          deliverySpecialNotes: obj.deliverySpecialNotes,
          jitAllocationItems: [],
          orderRebalanceItems: [],
          orderUploadId: uploadId,
        };
        // Fridge orders will contain production dates
        if (orderType === 'FRIDGE_OTHERS') {
          completeObject.itemsCount = [
            {
              itemName: obj.item,
              count: obj.count,
              productionDate: obj.productionDate.substring(0, 10),
            },
          ];
          completeObject.stockingIndex = obj.stockingIndex;
          if (obj.stockingIndex === '' || obj.stockingIndex === undefined) {
            completeObject.stockingIndex = 1;
          }
        } else {
          // Non fridge orders won't have production dates
          completeObject.itemsCount = [
            {
              itemName: obj.item,
              count: obj.count,
            },
          ];
        }
        if (obj.region != '') {
          completeObject.market = obj.region;
          for (const [key, value] of Object.entries(regions)) {
            if (key === obj.region) {
              completeObject.packingSlipGroup =
                allPackingSlipGroupValuesDic[value];
            }
          }
        }
        if (obj.wholesaleLocationId != '') {
          completeObject.wholesaleLocationId = obj.wholesaleLocationId;
        }
        if (obj.invoiceNumber != '') {
          completeObject.invoiceNumber = obj.invoiceNumber;
        }
        if (obj.purchaseOrderNumber != '') {
          completeObject.purchaseOrderNumber = obj.purchaseOrderNumber;
        }
        if (obj.guaranteed != '') {
          completeObject.guaranteed = obj.guaranteed;
        }
        if (clientCode !== 'Select') {
          completeObject.clientCode = clientCode;
        }
        if (dropDate) {
          completeObject.dropOffDate = dropDate.substring(0, 10);
        }
        if (obj.jitEligible === true) {
          completeObject.jitAllocationItems.push(obj.item);
        }
        if (obj.rebalanceEligible === true) {
          completeObject.orderRebalanceItems.push(obj.item);
        }
        if (groupKey in groupedOrders) {
          if (obj.locationId in groupedOrders[groupKey]) {
            // Fridge orders will contain production dates
            if (orderType === 'FRIDGE_OTHERS') {
              groupedOrders[groupKey][obj.locationId].itemsCount.push({
                itemName: obj.item,
                count: obj.count,
                productionDate: obj.productionDate.substring(0, 10),
              });
            } else {
              // Non fridge orders won't have production dates
              groupedOrders[groupKey][obj.locationId].itemsCount.push({
                itemName: obj.item,
                count: obj.count,
              });
            }
            if (obj.jitEligible === true) {
              groupedOrders[groupKey][obj.locationId].jitAllocationItems.push(
                obj.item,
              );
            }
            if (obj.rebalanceEligible === true) {
              groupedOrders[groupKey][obj.locationId].orderRebalanceItems.push(
                obj.item,
              );
            }
          } else {
            groupedOrders[groupKey][obj.locationId] = completeObject;
          }
        } else {
          groupedOrders[groupKey] = {};
          groupedOrders[groupKey][obj.locationId] = completeObject;
        }
      } else {
        remainingItems.push(obj);
      }
    }
    try {
      const uploadResult = await uploadFileToS3(
        fileContentsRaw,
        'fridge-wholesale-order-upload',
        uploadId,
      );
      // Handle successful upload
    } catch (uploadError) {
      // Handle upload error
      setSubmitError(true);
    }

    let success = 0;
    let fail = 0;
    const failedOrders = [];
    for (const date in groupedOrders) {
      for (const loc in groupedOrders[date]) {
        try {
          const response = await CreateOrderMutation(groupedOrders[date][loc]);
          success++;
        } catch (err) {
          failedOrders.push({
            data: groupedOrders[date][loc],
            error: err,
          });
          fail++;
        } finally {
          setCompletedFiles((prev) => prev + 1);
        }
      }
    }
    if (fail > 0) {
      setSubmitError(true);
      setIsSubmitting(false);
      toast.warning(
        `There was an error uploading ${fail} orders. Please, check the failed orders below.`,
        toastOptions,
      );
      setFailedOrdersInfo(failedOrders);
      setShouldShowSuccess(true);
    } else {
      track('Bulk Order Success', {
        count: success,
      });
      setShouldShowSuccess(true);
    }
  };

  const formatFileContent = (items) => {
    const formatted = [];
    let tempTotalItems = 0;
    let tempTotalProducts = 0;
    items.data.forEach((item) => {
      tempTotalItems += item.count;
      tempTotalProducts += 1;
      if (!formatted.find((fitem) => fitem.locationId === item.locationId)) {
        formatted.push({
          date: item.deliveryDate,
          locationId: item.locationId,
          numbersOfProducts: 1,
          totalItems: item.count,
          invoiceNumber: item.invoiceNumber,
          purchaseOrderNumber: item.purchaseOrderNumber,
        });
      } else {
        const foundItem = formatted.find(
          (fitem) => fitem.locationId === item.locationId,
        );
        foundItem.numbersOfProducts += 1;
        foundItem.totalItems += item.count;
      }
    });
    setTotalProducts(tempTotalProducts);
    setTotalItems(tempTotalItems);
    setFormattedFileContents(formatted);
    setNumFiles(formatted?.length);
  };
  const cleanLocationId = (locationId) => {
    const spaced = locationId.split(' ');
    if (spaced.length <= 2) {
      return spaced[0];
    }
    return locationId.split('#')[0];
  };
  const validateOrderExistence = async (submittedData) => {
    const [dropOffDate, deliveryDate, locationId, stockingIndex] =
      submittedData.split('#');
    //using deliveryDate to track since Orders doesn't allow querying by dropOffDate
    const findInvalidOrders = Object.keys(curOrders)?.forEach((date) => {
      const orderByDate = curOrders[date]?.forEach((order) => {
        const orderStockingIndex = order.stockingIndex
          ? order.stockingIndex
          : 1;
        if (
          ((order.dropOffDate === dropOffDate &&
            order.locationId === locationId &&
            orderStockingIndex === parseInt(stockingIndex)) ||
            orderStockingIndex > 2) &&
          !invalidSet.has(submittedData)
        ) {
          setInvalidOrderItems((prev) => [...prev, submittedData]);
          invalidSet.add(submittedData);
        }
      });
    });
    const findInvalidPars = Object.keys(curPars)?.forEach((date) => {
      const parByDate = curPars[date]?.forEach((par) => {
        const parStockingIndex = par.stockingIndex ? par.stockingIndex : 1;
        if (
          par.location === locationId &&
          parStockingIndex === parseInt(stockingIndex) &&
          par.dropOffDate === dropOffDate
        ) {
          setInvalidParItems((prev) => [...prev, submittedData]);
          invalidSet.add(submittedData);
        }
      });
    });
  };

  const validateline = (item) => {
    const item_keys = Object.keys(item);

    // the minimal keys needed to validate
    let keys = [
      'item',
      'count',
      'locationId',
      'deliveryDate',
      'deliverySpecialNotes',
      'region',
    ];
    // fridgeOrders will contain productionDates
    const fridgeOrderKeys = [
      'item',
      'count',
      'locationId',
      'deliveryDate',
      'productionDate',
      'deliverySpecialNotes',
      'stockingIndex',
      'rebalanceEligible',
    ];
    if (orderType === 'FRIDGE_OTHERS') {
      keys = fridgeOrderKeys;
    }
    // drop off date is optional for internal orders.
    if (orderType !== 'INTERNAL') {
      keys.push('dropOffDate');
    }
    if (clientCode === 'Wholesale') {
      keys.push('wholesaleLocationId');
      keys.push('invoiceNumber');
      keys.push('purchaseOrderNumber');
    }
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (!item_keys.includes(key) || item[key] === '') {
        return { status: -1, msg: `Invalid Column: ${key}` };
      }
    }
    if (!Object.keys(regions).includes(item.region)) {
      return { status: -1, msg: `Unknown Region: ${item.region}` };
    }
    // Check to see if item is valid
    if (!items.includes(item.item)) {
      return { status: -1, msg: `Unknown Item: ${item.item}` };
    }
    // Check to see if quantity of item is valud
    if (item.count < 0) {
      return {
        status: -1,
        msg: `Item ${item.item} must have quantity greater than 0`,
      };
    }
    // Check to see if location is valid if it's a fridge order
    if (
      orderType === 'FRIDGE_OTHERS' &&
      !locations.hasOwnProperty(item.locationId.split('#')[0])
    ) {
      return {
        status: -1,
        msg: `Unknown Location: ${item.locationId.split('#')[0]}`,
      };
    }

    if (
      orderType === 'FRIDGE_OTHERS' &&
      (item.jitEligible === '' || item.jitEligible === undefined)
    ) {
      return {
        status: -1,
        msg: `Unknown jit eligible for: ${item.name}`,
      };
    }

    if (
      orderType === 'FRIDGE_OTHERS' &&
      (item.rebalanceEligible === '' || item.rebalanceEligible === undefined)
    ) {
      return {
        status: -1,
        msg: `Unknown rebalance eligible for: ${item.name}`,
      };
    }

    if (
      orderType === 'FRIDGE_OTHERS' &&
      !(item.jitEligible === true || item.jitEligible === false)
    ) {
      return {
        status: -1,
        msg: `jitEligible cannot be applied for: ${item.item}. Accepted values are true or false. Please revise the format and resubmit.`,
      };
    }

    if (
      orderType === 'FRIDGE_OTHERS' &&
      !(item.rebalanceEligible === true || item.rebalanceEligible === false)
    ) {
      return {
        status: -1,
        msg: `rebalanceEligible cannot be applied for: ${item.item}. Accepted values are true or false. Please revise the format and resubmit.`,
      };
    }

    // Check to see if location is valid if it's a wholesale order
    if (
      clientCode === 'Wholesale' &&
      !locations.hasOwnProperty(item.wholesaleLocationId)
    ) {
      return {
        status: -1,
        msg: `Unknown Wholesale Location: ${item.wholesaleLocationId}`,
      };
    }

    const regEx = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
    // Ensure valid Delivery Date
    if (!item.deliveryDate.match(regEx)) {
      return {
        status: -1,
        msg: `Invalid Delivery Date: ${item.deliveryDate}`,
      };
    }

    // Ensure valid drop Off Date
    if (item.dropOffDate) {
      if (!item.dropOffDate.match(regEx)) {
        return {
          status: -1,
          msg: `Invalid Drop Off Date: ${item.dropOffDate}`,
        };
      }
      // Ensure drop off is after delivery
      const dropOffDate = moment(item.dropOffDate);
      const deliveryDate = moment(item.deliveryDate).subtract(1, 'day');
      if (dropOffDate.isBefore(deliveryDate)) {
        return {
          status: -1,
          msg:
            `Drop off Date (${item.dropOffDate}) must be equal or after the day before ` +
            `Delivery Date (${item.deliveryDate})`,
        };
      }
    }
    // Ensure valid Production Date
    if (keys.includes('productionDate') && !item.productionDate.match(regEx)) {
      return {
        status: -1,
        msg: `Invalid Production Date: ${item.productionDate}`,
      };
    }
    // Ensure Delivery is after production
    if (
      keys.includes('productionDate') &&
      item.deliveryDate <= item.productionDate
    ) {
      return {
        status: -1,
        msg: `Production Date (${item.productionDate}) must be before Delivery Date(${item.deliveryDate})`,
      };
    }
    if (
      item.wholesaleLocationId &&
      opportunityNames[item.wholesaleLocationId]
        .toLowerCase()
        .includes('costco')
    ) {
      const itemCount = item.count;
      if (itemCount % 6 != 0) {
        return {
          status: -1,
          msg: `Costco SKU quantity of ${item.count} for ${item.item} must be a multiple of 6.`,
        };
      }
    }

    // apply location validations only for non-internal orders
    if (orderType !== 'INTERNAL') {
      let curLocation = '';
      if (item.wholesaleLocationId) {
        curLocation = item.wholesaleLocationId;
      } else {
        curLocation = item.locationId.split('#')[0];
      }
      const locationsFields = salesforceFields[curLocation];
      if (!locationsFields?.routingTag) {
        return {
          status: -1,
          msg: `Error with Routing Tag for ${curLocation}`,
        };
      }
      if (!locationsFields?.fulfillmentCenter) {
        return {
          status: -1,
          msg: `Error with Territory for ${curLocation}`,
        };
      }

      if (locations[curLocation] === 'WHOLESALE') {
        if (!locationsFields?.deliveryInstructions) {
          return {
            status: -1,
            msg: `Error with Delivery Instructions for ${curLocation}`,
          };
        }

        if (!locationsFields?.timeZone) {
          return {
            status: -1,
            msg: `Error with Timezone for ${curLocation}`,
          };
        }

        if (locationsFields?.status !== 'LIVE') {
          return {
            status: -1,
            msg: `Error with Status for ${curLocation}`,
          };
        }
      }

      if (locations[curLocation] === 'FRIDGE') {
        if (!locationsFields?.parkToFridgeInstructions) {
          return {
            status: -1,
            msg: `Error with Park To Fridge Instructions for ${curLocation}`,
          };
        }
      }
    }

    return { status: 1, msg: '' };
  };

  const cleanData = (results) => {
    const group = {};
    const toReturn = [];
    for (const rec in results.data) {
      if (results.data[rec].item === '') {
        continue;
      }
      const loc = `${results.data[rec].locationId}#${results.data[rec].deliverySpecialNotes}`;
      const delDate = results.data[rec].deliveryDate;
      const item = results.data[rec].item;
      const count = results.data[rec].count;
      const proDate = results.data[rec].productionDate;
      const dropDate = results.data[rec].dropOffDate;
      const region = results.data[rec].region;
      const groupKey = `${delDate}#${dropDate}`;
      const wholesaleLocationId = results.data[rec].wholesaleLocationId;
      const invoiceNumber = results.data[rec].invoiceNumber;
      const purchaseOrderNumber = results.data[rec].purchaseOrderNumber;
      const guaranteed = results.data[rec].guaranteed;
      const stockingIndex = results.data[rec].stockingIndex;
      const jitEligible = results.data[rec].jitEligible;
      const rebalanceEligible = results.data[rec].rebalanceEligible;
      if (!(groupKey in group)) {
        group[groupKey] = {};
      }
      if (!(loc in group[groupKey])) {
        group[groupKey][loc] = {};
      }
      if (!(item in group[groupKey][loc])) {
        group[groupKey][loc][item] = { count: 0, productionDate: proDate };
      }
      if (parseInt(count, 10) >= 0) {
        group[groupKey][loc][item].count += parseInt(count, 10);
      }
      if (region !== '') {
        group[groupKey][loc][item].region = region;
      }
      if (stockingIndex !== '') {
        group[groupKey][loc][item].stockingIndex = stockingIndex;
      }
      if (wholesaleLocationId !== '') {
        group[groupKey][loc][item].wholesaleLocationId = wholesaleLocationId;
      }
      if (
        guaranteed !== undefined &&
        guaranteed !== '' &&
        guaranteed.toString().toLowerCase() === 'true'
      ) {
        group[groupKey][loc][item].guaranteed = true;
      }

      if (invoiceNumber !== '') {
        group[groupKey][loc][item].invoiceNumber = invoiceNumber;
      }

      if (purchaseOrderNumber !== '') {
        group[groupKey][loc][item].purchaseOrderNumber = purchaseOrderNumber;
      }
      if (jitEligible !== '') {
        group[groupKey][loc][item].jitEligible = jitEligible;
      }
      if (rebalanceEligible !== '') {
        group[groupKey][loc][item].rebalanceEligible = rebalanceEligible;
      }
    }
    for (const d in group) {
      for (const l in group[d]) {
        for (const i in group[d][l]) {
          const dates = d.split('#');
          toReturn.push({
            item: i,
            count: group[d][l][i].count,
            locationId: l,
            deliveryDate: dates[0],
            productionDate: group[d][l][i].productionDate,
            deliverySpecialNotes: l.split('#')[1],
            dropOffDate: dates[1],
            region: group[d][l][i].region,
            wholesaleLocationId: group[d][l][i].wholesaleLocationId,
            invoiceNumber: group[d][l][i].invoiceNumber,
            purchaseOrderNumber: group[d][l][i].purchaseOrderNumber,
            guaranteed: group[d][l][i].guaranteed,
            stockingIndex: group[d][l][i].stockingIndex,
            jitEligible: group[d][l][i].jitEligible,
            rebalanceEligible: group[d][l][i].rebalanceEligible,
          });
        }
      }
    }
    results.data = toReturn;
    return results;
  };
  useEffect(() => {
    if (fileContents?.data?.length > 0) {
      if (curOrders || curPars) {
        setIsValidationLoading(true);
        try {
          const newOrders = [
            ...new Set(
              fileContents?.data.map((item) => {
                console.log('filecontentsitem: ', item);
                const cleanLocation = cleanLocationId(item.locationId);
                return `${item.dropOffDate}#${
                  item.deliveryDate
                }#${cleanLocation}#${
                  item.stockingIndex ? item.stockingIndex : 1
                }`;
              }),
            ),
          ];
          const idSet = new Set();
          if (newOrders) {
            newOrders.forEach((order) => {
              // only validate by order, not by sku
              if (!idSet.has(order)) {
                idSet.add(order);
                validateOrderExistence(order);
              }
            });
          }
        } catch (e) {
          console.error(e);
          setIsValidationLoading(false);
        }
      }
      setIsValidationLoading(false);
    }
  }, [fileContents]);

  const handleFileChange = (event) => {
    setInvalidOrderItems([]);
    setInvalidParItems([]);
    setIsValidationLoading(true);
    invalidSet = new Set();
    window.Papa.parse(event.target.files[0], {
      header: true,
      download: true,
      dynamicTyping: true,
      complete: async (rawResults, file) => {
        const cleaned = cleanData(rawResults);
        const results = {};
        const errorPerItem = [];
        cleaned.data.forEach((cleanedItem, i) => {
          let error = '';
          // Check if line has an error
          const result = [cleanedItem]
            .map((item) => {
              item.item = item.item.toString();
              return item;
            })
            .filter((obj) => {
              if (validateline(obj).msg) {
                error = validateline(obj).msg;
              }
              return validateline(obj).status < 1;
            });
          // Either push the error object or an emtpy object, used for knowing which line is problematic
          if (result.length) {
            track('Bulk Order Error', {
              type: error,
            });
            errorPerItem.push({
              item: cleanedItem.locationId.split('#')[0],
              msg: error,
            });
          }
          results.error = errorPerItem;
        });
        results.data = cleaned.data
          .map((item) => {
            item.item = item.item.toString();
            return item;
          })
          .filter((obj) => validateline(obj).status > 0);
        const fileContentsReal = await file.text();
        setFileContentsRaw(fileContentsReal);
        setFileContents(results);
        formatFileContent(results);
        if (results.error.length > 0) {
          setFileErrors(results.error);
          track('Bulk Order Errors', {
            count: results.error.length,
          });
        }
      },
    });
  };

  return (
    <StyledSection>
      {shouldShowSuccess && (
        <div className="successMessage">
          <h1>Your order has been uploaded</h1>
          <Alert bsStyle="success">
            <h4>
              <b>SUCCESS ORDERS</b>
            </h4>
            <hr />
            <div>
              <b>
                Successfully uploaded{' '}
                {formattedFileContents?.length -
                  (failedOrdersInfo?.length || 0)}{' '}
                orders
              </b>
            </div>
            <div>
              Order Upload Id:{' '}
              <span id="new-order-upload-id">{orderUploadId}</span>
            </div>
          </Alert>
          {failedOrdersInfo.length > 0 && (
            <Alert bsStyle="warning">
              <h4>
                <b>FAILED ORDERS</b>
              </h4>
              <hr />
              {failedOrdersInfo.map((failedOrder) => (
                <>
                  <div>
                    <b>Order: </b>
                    {failedOrder.data.locationId} <br />
                    <b>Error: </b>
                    {failedOrder.error.errors[0].message ||
                      failedOrder.error.message ||
                      JSON.stringify(failedOrder.error)}
                  </div>
                  <hr />
                </>
              ))}
            </Alert>
          )}
          <FFButtonComp
            buttonText="Create New Order"
            handleClick={() => {
              history.push('/orders-v2/bulk-create');
              window.location.reload();
            }}
          />
        </div>
      )}
      {!shouldShowSuccess && (
        <>
          {submitError && (
            <div className="submitError">
              There was an error uploading the orders. You may not have
              permissions to upload orders or there may have been a connection
              error. Please check your permissions and try uploading again.
            </div>
          )}
          <Prompt
            when={shouldBlockNavigation}
            message="You have unsaved changes, are you sure you want to leave?"
          />
          <CreateOptions defaultValue="bulk" history={history} />
          <Formik
            data-testid="formik"
            initialValues={{
              clientCode: 'Select',
              orderType: 'Select',
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setShouldBlockNavigation(false);
              createOrders(values);
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              submitForm,
              values,
            }) => {
              return (
                <form
                  data-testid="bulkOrdersCreateForm"
                  onSubmit={handleSubmit}
                >
                  {isInitLoading ? (
                    <div className="loadingWrapper">
                      <div className="loading">
                        <div
                          className="bar"
                          style={{ width: `${initialProgressPercent}%` }}
                        />
                        <div className="barText">{initialProgressPercent}%</div>
                      </div>
                      <p>
                        Initial Data Loading:{' '}
                        <b>{`${Object.keys(curOrders)?.length} / 16`}</b>
                      </p>
                    </div>
                  ) : (
                    <div className="dropdowns">
                      <Select
                        errors={errors}
                        touched={touched}
                        label="Order Type"
                        id="orderType"
                        data-testid="selectBulkOrderCreate"
                        options={[
                          { value: 'Select', label: '-- Select One --' },
                          {
                            value: 'FRIDGE_OTHERS',
                            label: 'Fridge Order',
                            id: 'fridgeOthersSelection',
                          },
                          { value: 'CUSTOMER', label: 'New Channels' },
                          { value: 'INTERNAL', label: 'Internal Orders' },
                        ]}
                        onChange={(v) => {
                          setFieldTouched('orderType', true);
                          setFieldValue('orderType', v.value);
                          setOrderType(v.value);
                        }}
                        value="Select"
                      />
                      {/* {values.orderType === 'FRIDGE' && (
                  <Select
                    errors={errors}
                    touched={touched}
                    label="Fridge Order Type"
                    id="clientCode"
                    options={[
                      { value: 'Select', label: '-- Select One --' },
                      { value: 'Midwest', label: 'Midwest' },
                      { value: 'Northeast', label: 'Northeast' },
                    ]}
                    onChange={v => {
                      setFieldTouched('clientCode', true);
                      setFieldValue('clientCode', v.value);
                    }}
                    value="Select"
                  />
                )} */}
                      {values.orderType === 'CUSTOMER' && (
                        <Select
                          errors={errors}
                          touched={touched}
                          label="New Channel Order Type"
                          id="clientCode"
                          options={[
                            { value: 'Select', label: '-- Select One --' },
                            { value: 'Wholesale', label: 'Wholesale' },
                            { value: 'Catering', label: 'Catering' },
                          ]}
                          onChange={(v) => {
                            setFieldTouched('clientCode', true);
                            setFieldValue('clientCode', v.value);
                            setClientCode(v.value);
                          }}
                          value="Select"
                        />
                      )}
                      {values.orderType === 'INTERNAL' && (
                        <Select
                          errors={errors}
                          touched={touched}
                          label="Internal Order Type"
                          id="clientCode"
                          options={[
                            { value: 'Select', label: '-- Select One --' },
                            { value: 'Pulping', label: 'Pulping' },
                            { value: 'MenuRD', label: 'MenuRD' },
                            { value: 'QA', label: 'QA' },
                            { value: 'Marketing', label: 'Marketing' },
                            { value: 'LogisticsRD', label: 'LogisticsRD' },
                            { value: 'Sales', label: 'Sales' },
                            { value: 'SafetyStock', label: 'Safety Stock' },
                          ]}
                          onChange={(v) => {
                            setFieldTouched('clientCode', true);
                            setFieldValue('clientCode', v.value);
                            setClientCode(v.value);
                          }}
                          value="Select"
                        />
                      )}
                    </div>
                  )}

                  {values.orderType !== 'Select' && (
                    <div className="fileWrapper">
                      <label htmlFor="file">
                        Select File
                        <input
                          id="file"
                          name="file"
                          type="file"
                          onChange={(event) => {
                            setFileErrors(null);
                            setFieldValue('file', event.currentTarget.files[0]);
                            handleFileChange(event);
                          }}
                          className="form-control"
                        />
                      </label>
                    </div>
                  )}
                  {invalidParItems && (
                    <>
                      {invalidParItems?.length > 0 && (
                        <b>Pars that already exist</b>
                      )}
                      <div style={{ color: 'red' }}>
                        {invalidParItems.map((item) => {
                          const [
                            dropOffDate,
                            deliveryDate,
                            locationId,
                            stockingIndex,
                          ] = item.split('#');
                          return (
                            <b>
                              {locationId} | {dropOffDate} | Stocking Index:{' '}
                              {stockingIndex}
                              <br />
                            </b>
                          );
                        })}
                      </div>
                    </>
                  )}
                  {invalidOrderItems && (
                    <>
                      {invalidOrderItems?.length > 0 && (
                        <b>Orders that already exist</b>
                      )}
                      <div style={{ color: 'red' }}>
                        {invalidOrderItems.map((item) => {
                          const [
                            dropOffDate,
                            deliveryDate,
                            locationId,
                            stockingIndex,
                          ] = item.split('#');
                          if (!stockingIndex || stockingIndex > 2) {
                            return (
                              <b>
                                Stocking Index for Order at {locationId} with a
                                drop off date of {dropOffDate} is invalid
                                <br />
                              </b>
                            );
                          } else {
                            return (
                              <b>
                                <br />
                                {locationId} | {dropOffDate} | Stocking Index:{' '}
                                {stockingIndex}
                                <br />
                              </b>
                            );
                          }
                        })}
                      </div>
                    </>
                  )}
                  {formattedFileContents &&
                    !isInitLoading &&
                    !isValidationLoading && (
                      <>
                        <h3>Order Information</h3>
                        {fileErrors && fileErrors.length > 0 && (
                          <div className="errors">
                            <div className="colorBar">
                              <span>Errors</span>
                            </div>
                            <div className="title">
                              Please fix the following errors and reupload your
                              file:
                            </div>
                            <ul className="errorList">
                              {fileErrors
                                .filter((item) => item.msg)
                                .map((err, i) => {
                                  return (
                                    <li>
                                      <div>
                                        <b>
                                          Order{' '}
                                          {formattedFileContents.length + i + 1}{' '}
                                          was not uploaded
                                        </b>
                                      </div>
                                      {err.item && (
                                        <div>
                                          LocationId {err.item} has an error:{' '}
                                          {err.msg}
                                        </div>
                                      )}
                                      {!err.item && (
                                        <div>
                                          Can't find locationId "{err.item}"
                                        </div>
                                      )}
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        )}
                        <div>
                          <div className="uploadSummaryItems">
                            <div>
                              <h6>Upload Summary</h6>
                              <ul className="uploadSummary">
                                <li>{formattedFileContents.length} Orders</li>
                                <li>
                                  {totalProducts} Products, {totalItems} items
                                </li>
                              </ul>
                            </div>
                            <nav className="viewNavigation">
                              <ul>
                                <li>
                                  <a
                                    className={
                                      orderInfoDisplayType === 'summary'
                                        ? 'active'
                                        : ''
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setOrderInfoDisplayType('summary');
                                    }}
                                    href="#"
                                  >
                                    <Summary />
                                    <span>Summary</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className={
                                      orderInfoDisplayType === 'detailed'
                                        ? 'active'
                                        : ''
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setOrderInfoDisplayType('detailed');
                                    }}
                                    href="#"
                                  >
                                    <Detail />
                                    <span>Detail</span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                        {orderInfoDisplayType === 'summary' && (
                          <table className="filePreview">
                            <thead>
                              <tr>
                                <th>Order</th>
                                <th>Date</th>
                                <th>Customer</th>
                                <th>Number of Products</th>
                                <th>Total Items</th>
                                <th>Purchase Order Number</th>
                                <th>Invoice Number</th>
                              </tr>
                            </thead>
                            <tbody>
                              {formattedFileContents &&
                                formattedFileContents.map((item, i) => {
                                  return (
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{item.date}</td>
                                      <td>{item.locationId}</td>
                                      <td>{item.numbersOfProducts}</td>
                                      <td>{item.totalItems}</td>
                                      <td>{item.purchaseOrderNumber}</td>
                                      <td>{item.invoiceNumber}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        )}
                        {orderInfoDisplayType === 'detailed' && (
                          <table className="filePreview">
                            <thead>
                              <tr>
                                <th>Item</th>
                                <th>Count</th>
                                <th>Delivery Date</th>
                                <th>Delivery Special Notes</th>
                                <th>Dropoff Date</th>
                                <th>Region</th>
                                <th>Stocking Index</th>
                              </tr>
                            </thead>
                            {fileContents.data &&
                              fileContents.data.map((item, i) => {
                                return (
                                  <tr>
                                    <td>{item.item}</td>
                                    <td>{item.count}</td>
                                    <td>{item.deliveryDate}</td>
                                    <td>{item.deliverySpecialNotes}</td>
                                    <td>{item.dropOffDate}</td>
                                    <td>{item.region}</td>
                                    <td>{item.stockingIndex}</td>
                                  </tr>
                                );
                              })}
                            <tbody />
                          </table>
                        )}
                      </>
                    )}
                  {isValidationLoading && <Loading />}
                  {isSubmitting && (
                    <div className="loadingWrapper">
                      <div className="loading">
                        <div
                          className="bar"
                          style={{ width: `${progressPercent}%` }}
                        />
                        <div className="barText">{progressPercent}%</div>
                      </div>
                      <p>
                        Orders uploaded:{' '}
                        <b>{`${completedFiles} / ${numFiles}`}</b>
                      </p>
                    </div>
                  )}
                  <div className="actions">
                    <FFButtonComp
                      buttonText="Submit Order"
                      disabled={
                        Boolean(fileErrors && fileErrors.length) ||
                        !formattedFileContents ||
                        invalidOrderItems?.length > 0 ||
                        invalidParItems?.length > 0 ||
                        isInitLoading ||
                        isValidationLoading ||
                        (values.orderType === 'Select' &&
                          values.clientCode === 'Select') ||
                        (values.orderType === 'CUSTOMER' &&
                          values.clientCode === 'Select') ||
                        (values.orderType === 'INTERNAL' &&
                          values.clientCode === 'Select')
                      }
                      handleClick={() => {
                        setIsSubmitting(true);
                        submitForm();
                      }}
                      type="submit"
                      working={isSubmitting}
                      dataTestId="bulkOrdersCreate-submit"
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
        </>
      )}
    </StyledSection>
  );
};

OrdersBulkCreate.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default OrdersBulkCreate;
